<template>
  <div>
    <div class="header">
      <h4>工资包功能</h4>
    </div>
    <el-row :gutter="12" style="margin-top: 32px">
      <el-col :span="8" v-access data-ctrl="task.enterCard">
        <el-card shadow="always" v-prev path="/system/task/list">
          <el-button size="medium" type="text">我的工资包</el-button>
        </el-card>
      </el-col>
      <el-col :span="8" v-access data-ctrl="task.enterPackageCard">
        <el-card shadow="always" v-prev path="/system/task/list">
          <el-button size="medium" type="text">我代理企业的工资包</el-button>
        </el-card>
      </el-col>
    </el-row>
    <!-- <div class="header">
      <h4>用户统计</h4>
    </div>
    <el-row :gutter="20" style="margin-top: 32px">
      <el-col :span="12">
        <TaskEchart4></TaskEchart4>
      </el-col>
      <el-col :span="12">
        <TaskEchart3></TaskEchart3>
      </el-col>

      <el-col :span="12">
        <TaskEchart1></TaskEchart1>
      </el-col>
      <el-col :span="12">
        <TaskEchart5></TaskEchart5>
      </el-col>
      <el-col :span="12">
        <TaskEchart2></TaskEchart2>
      </el-col>
    </el-row>-->
  </div>
</template>

<script>
export default {
  components: {
    // TaskEchart1: () => import("./task-echart-1"),
    // TaskEchart2: () => import("./task-echart-2"),
    // TaskEchart3: () => import("./task-echart-3"),
    // TaskEchart4: () => import("./task-echart-4"),
    // TaskEchart5: () => import("./task-echart-5")
  },
  created () { },
  data () {
    return {
      index: 1
    };
  },
  methods: {
    getUserStastic () {
      this.index++;
    }
  }
};
</script>

<style></style>
